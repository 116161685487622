.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tumbnail {
  height: 400px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Container needed to position the button. Adjust the width as needed */
.container {
  position: relative;
  /* max-width: 100%;
  height:100%; */
  /* display: inline-block; */
  /* padding: 0 2px;  */
  text-align: center;
}

.container:hover{
   
  /* min-width: 600px; */
  background-color: rgb(251, 251, 251);
  box-shadow: '0px 5px 10px rgba(0,0,0,0.8)'
}
/* Make the image responsive */
.container img {
  max-width:  100%;

}
@media only screen and (max-width: 600px) {
  .container {
    position: relative;
    /* max-width: 100%; */
    /* height:400px; */
    width:100%;
    display: inline-block;

    text-align: center;
    height: auto;
  }
  .container img {
    width: 100%;
  height: auto;
  max-height: none ;
  }
}
/* Style the button and place it in the middle of the container/image */
.container .controls {
  position: absolute;
  bottom: 0%;
  right: 0%;

  /* background-color: #555; */
  color: white;
  font-size: 16px;
  /* padding: 12px 24px; */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  min-width: 100px;
  opacity: 0;
  transition: opacity .35s ease;
}
.container .controls .btn {


  /* background-color: #555; */
  color: white;
  font-size: 16px;
  /* padding: 12px 24px; */
  border: 1px;
  border-color: aquamarine;
  cursor: pointer;
  border-radius: 5px;
  min-width: 100px;

}

.container:hover .controls {
  /* background-color: black; */
  opacity: 1;
}
.container .controls .btn:hover {
  background-color: black;
  /* opacity: 1; */
}
.btn {


  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: 1px;
  border-color: aquamarine;
  cursor: pointer;
  border-radius: 5px;
  min-width: 100px;
}

.shadow {
  -webkit-filter: drop-shadow( 0px 0px 4px rgba(0, 0, 0, .4));
  filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .7));
  /* Similar syntax to box-shadow */
}

.container .controls_left {
  
  width:300px;
  display: none;

}
.container:hover .controls_left {
  /* background-color: black; */
  /* display: inline-block */
}